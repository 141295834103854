// Change website animations
const animation = {
  // make it false to switch off fade-up animation
  animate: true,
  // animation playing duration
  duration: 750,
  // if true, animation plays only once when element comes on screen
  once: false
};
// Change your display name on the landing display
const header = {
  name: "Zubair Hossain"
};
const background = {
  // Options: Snow or Particle
  type: "Particle"
};
// Write a para about yourself here
// To update your image, go to './styles/images.css'
const section2title = "About Me";
const about = {
  paragraph:
    "Deeply engaged in artificial intelligence and data analytics, I excel in leveraging Python, Java, SQL and cloud computing to solve complex problems, underscored by leadership in tech societies and hands-on technical roles.\n\I am currently studying Computing Science (Honours) at the University of Technology Sydney, majoring in AI & Data Analaytics, expected to graduate in 2025."
};
// Edit your skill and the percentage you know about it
// To Add a skill, copy any one below and paste it after the last comma
const skillsBar = [
  {
    name: "HTML5",
    // To add a custom svg instead of font-awesome icons, add svg path below otherwise just comment it out
    svg: "M0 32l34.9 395.8L191.5 480l157.6-52.2L384 32H0zm308.2 127.9H124.4l4.1 49.4h175.6l-13.6 148.4-97.9 27v.3h-1.1l-98.7-27.3-6-75.8h47.7L138 320l53.5 14.5 53.7-14.5 6-62.2H84.3L71.5 112.2h241.1l-4.4 47.7z",
    faClass: "fab fa-html5"
  },
  {
    name: "CSS3",
    // svg: '',
    faClass: "fab fa-css3"
  },
  {
    name: "Javascript",
    // svg: '',
    faClass: "fab fa-js"
  },
  {
    name: "Python",
    // svg: '',
    faClass: "fab fa-python"
  },
  {
    name: "Java",
    // svg: '',
    faClass: "fab fa-java"
  },
  {
    name: "Database",
    // svg: '',
    faClass: "fas fa-database"
  },
  {
    name: "AWS",
    // svg: '',
    faClass: "fab fa-aws"
  }
];

const section3Title = "Projects";
const projects = [
  {
    // Add image in './styles/images.css' in #project1
    id: "project1",
    name: "Detecting diabetes using ensemble methods",
    skills: ["Python, AI, Data"],
    url: "https://github.com/zhossain7/ensemblediabetes"
  },
  {
    // Add image in './styles/images.css' in #project2
    id: "project2",
    name: "Detecting melanoma using machine vision",
    skills: ["Python, AI, Data"],
    url: "https://github.com/zhossain7/detectingmelanoma"
  }
];
// Contact form text, and Formspree link(to send a submit contact through their API as in contact.js)
// To get your own jotform link, go to https://formspree.io/
// If you have the link already, paste it in the contactUrl below
const section5Title = "Get in Touch";
const contact = {
  pitch:
    "If you wish to connect with me or want to access my resume, please use the links below. Or please us the contact form and I'll get back to you via email.",
  copyright: "Zubair Hossain",
  contactUrl: ""
};
// Paste your respective social media links. You can omit any if you dont have it
// Upload your resume in your drive, get the shareable link and paste it in the resume section
const social = {
  github: "https://github.com/zhossain7",
 // facebook: "https://facebook.com",
  // twitter: "https://twitter.com",
  // instagram: "https://instagram.com",
  linkedin: "https://www.linkedin.com/in/zubairhossain7/",
  resume: "https://zubaircv.tiiny.site"
};
// Don't change anything here
export {
  animation,
  header,
  background,
  about,
  skillsBar,
  projects,
  contact,
  social,
  section2title,
  section3Title,
  section5Title
};
